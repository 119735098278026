<template>
  <div>
    <!-- Modal  -->
    <div v-if="showModal" class="modal fade in show modal-active modal-open" id="exportModal">
      <div class="modal-dialog modal-lg">
        <div v-if="updating">
          <div class="loader-container w-100">
            <div class="row justify-content-center">
              <div class="col-md-4 d-flex justify-content-center">
                <div class="loader">Loading...</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Export order history</h4>
            <button type="button" class="close" v-on:click="hideExportModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div>From</div>
                <select class="inputfield p-1" style="height: 42px;" v-model="exportStart">
                  <option v-for="date in dateList" :value="date" v-bind:key="date">{{date}}</option>
                </select>
              </div>
              <div class="col-md-6">
                <div>Until</div>
                <select class="inputfield p-1" style="height: 42px;" v-model="exportEnd">
                  <option v-for="date in dateList" :value="date" v-bind:key="date">{{date}}</option>
                </select>
              </div>
            </div>
          </div><!-- Modal body end -->
          <!-- Modal footer -->
          <div class="modal-footer">
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-outline-primary btn-block" v-on:click="hideExportModal()">Cancel</button></div>
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="exportOrders()">Export via email</button></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop show"></div>
    <!--  Mobile view  -->
    <SamAccordion
      v-if="windowWidth < mobileView"
      :title="`${$store.state.totalOrderCount > 1 ? 'Orders': 'Order'} (${$store.state.totalOrderCount !== null ? $store.state.totalOrderCount : ''}) `"
      title-payload="filters"
      accordionId="samAccordianOrders"
    >
      <v-select
        v-model="orderFilter"
        class="sam-input sam-select mb-4"
        label="name"
        :options="orderList"
        :searchable="true"
        :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched"
        placeholder="All orders IDs"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="statusFilter"
        label="name"
        :options="allStatuses"
        :reduce="s => s.value"
        :searchable="true"
        :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched"
        placeholder="All statuses"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="userCampaignFilter"
        :options="usersAndCampaigns"
        :searchable="true"
        :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched || !isManager"
        :placeholder="isManager ? 'All users and campaigns' : 'User'"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="recipientFilter"
        :options="recipients"
        :searchable="true"
        :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched"
        placeholder="All recipients"
      ></v-select>
      <SamSecondaryButton @buttonAction="openExportModal()" text="Export as XLS" :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched"/>
    </SamAccordion>
    <!--  Desktop view  -->
    <SamTableRow v-else headerRow>
      <SamTableColumn>
        <v-select
          v-model="orderFilter"
          class="sam-input sam-select"
          label="name"
          :options="orderList"
          :searchable="true"
          :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched"
          placeholder="All orders IDs"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          label="name"
          v-model="statusFilter"
          :options="allStatuses"
          :reduce="s => s.value"
          :searchable="true"
          :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched"
          placeholder="All statuses"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="userCampaignFilter"
          :options="usersAndCampaigns"
          :searchable="true"
          :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched || !isManager"
          :placeholder="isManager ? 'All users and campaigns' : 'User'"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="recipientFilter"
          :options="recipients"
          :searchable="true"
          :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched"
          placeholder="All recipients"
        ></v-select>
      </SamTableColumn> 
      <div class="sam-counts lease-counts d-none d-md-block col-md-3 d-flex justify-content-center" v-if="$store.state.ordersFetched">
         <SamParagraph :text="`Showing ${$store.state.totalOrderCount} ${$store.state.totalOrderCount === 1 ? 'order' : 'orders'}`"></SamParagraph>
      </div>
    </SamTableRow>
    <div v-if="!$store.state.ordersFetched">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div 
        @click="openEdit(order)"
        v-for="order in orders" 
        v-bind:key="order.id"
      >
        <SamTableRow hoverable noSpace clickableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph :text="order.order_id" bolded tableItem/>
            <SamParagraph :text="parseDate(order.created)" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Status" bolded/>
            <div v-if="order.requires_approval">
              <SamTag
                v-if="order.approval_time"
                :text="`${order.approval_time && order.is_approved ? 'Approved' : 'Declined'}`"
                :active="order.approval_time && order.is_approved"
                :alert="order.approval_time && !order.is_approved"
                tableItem
              />
              <SamTag
                v-else
                text="Approval needed"
                warning
                tableItem
              />
            </div>
            <SamTag
              v-if="order.campaign_invoice"
              :text="`${order.invoice_paid_time == null ? 'Invoice required' : 'Invoiced'}`"
              :active="order.invoice_paid_time !== null"
              :warning="order.invoice_paid_time == null"
              tableItem
            />
            <SamTag
              v-if="order.is_approved && order.store_order_carriers && order.store_order_carriers.length !== 0"
              :text="getCustomCarrierStatus(order.store_order_carriers).name"
              :active="getCustomCarrierStatus(order.store_order_carriers).active"
              :warning="getCustomCarrierStatus(order.store_order_carriers).warning"
              :alert="getCustomCarrierStatus(order.store_order_carriers).alert"
              tableItem
            />
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="User or campaign" bolded/>
              <SamParagraph :text="`${order.campaign_id ? order.campaign_name : order.user_name}`" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Recipient" bolded/>
              <SamParagraph :text="order.recipient_name" tableItem/>
              <SamParagraph :text="order.recipient_email" tableItem/>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="openEdit(order)"  text="View" noSpace/>
            </SamTableColumn>
          </SamTableRow>
      </div>
      <div v-for="lOrder in filteredLegacyOrders()" v-bind:key="lOrder.id">
        <div class="row">
          <div class="col-md-2">{{lOrder.order_id}}<br /><span class="price">{{parseDate(lOrder.date)}}</span></div>
          <div class="col-md-2">{{lOrder.user_name}}</div>
          <div class="col-md-2">
            <div>{{lOrder.address_name}}</div>
            <div class="mt-2">{{lOrder.recipient_name}}</div>
            <div>{{lOrder.address_address}}</div>
          </div>
          <div class="col-md-4">
            <div class="row" v-for="(p, index) in lOrder.products" v-bind:key="index">
                <div class="col-md-8">
                  <div>{{p.product_name}}</div>
                  <div class="price">&nbsp;</div>
                </div>
                <div class="col-md-4">
                  <div>{{p.price_per_item}}€</div>
                  <div>x{{p.quantity}}</div>
                </div>
            </div>
          </div>
          <div class="col-md-2">&nbsp;</div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr class="mt-2 mb-2" />
          </div>
        </div>
      </div>
      <div class="row" v-if="$store.state.moreOrdersAvailable">
        <div class="col-5">
        </div>
        <div class="col-2">
          <div class="row justify-content-center">
            <div class="mini-loader">Loading...</div>
          </div>
          <div class="mt-2">
            Loading more results..
          </div>
        </div>
        <div class="col-5">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'
import SamTag from '../../../components/SamTag.vue'

export default {
  name: 'OrderListing',
    components: { 
      SamAccordion,
      SamTableRow,
      SamTableColumn,
      SamParagraph,
      SamSecondaryButton,
      SamTag
    },
  props: [
    'orders'
  ],
  data() {
    return {
      orderFilter: null,
      statusFilter: null,
      userCampaignFilter: null,
      campaignFilter: null,
      recipientFilter: null,
      filteredOrderCount: null,
      filteredLegacyOrderCount: null,
      showModal: false,
      exportStart: "",
      exportEnd: "",
      updating: false,
      hash: "",
      scroll: null,
      visibleCount: 50,
      visibleLegacyCount: 0,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
      customCarrierStatuses: [
        {"name": "Unprocessed", "value": 6},
        {"name": "Processed", "value": 7},
        {"name": "Delivered", "value": 8},
        {"name": "Exception", "value": 9},
        {"name": "Tracking available", "value": 14},
        {"name": "No tracking yet", "value": 15},
      ]
    }
  },
  created: function () {
    this.setFiltersWithHash(window.location.hash.substring(1));
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted(){
    //Check window width
     window.addEventListener('resize', this.getDimensions);
    // Fetch first 25 orders in mount
    if(this.orders.length < 25 && !this.$store.state.ordersFetched){
      // get orders and then set update to false
      if(this.filterParams){
        this.$store.dispatch('fetchOrders', {append: false, filters: this.filterParams});
      } else {
        this.$store.dispatch('fetchOrders', {append: false});
      }
    } else {
      // already got orders, check/set filters
      if(this.$store.state.orderFilters !== this.filterParams){
        // Different filters, fetch orders
        this.$store.dispatch('clearAndFetchOrders', {append: false, filters: this.filterParams});
      }
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  watch: {
    filteredOrderCount(newVal, oldVal) {
      if (newVal !== oldVal) {
        // reset visibleCount
        this.visibleCount = 50
      }
    },
    filterParams: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        let hash = window.location.hash.substring(1)
        if (newVal !== hash) {
          window.location.hash = newVal && newVal.length > 1 ? newVal : ""
          this.hash = newVal
        }
        // Clear orders from VUEX and fetch first batch from back end
        this.$store.dispatch('clearAndFetchOrders', {append: false, filters: newVal})
      }
    }
  },
  computed: {
    userList() {
      const users = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.user_names : []
      const lusers = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.legacy_user_names : []
      const userSet = new Set([...users, ...lusers])
      const userNames = Array.from(userSet)
      // try to sort by last name
      userNames.sort((a, b) => (a.split(" ").slice(-1)[0].toLowerCase() > b.split(" ").slice(-1)[0].toLowerCase()) ? 1 : -1)
      return [...userNames];
    },
    // TODO: for removing
    addressList() {
      const addrs = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.addresses : []
      const laddrs = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.legacy_addresses : []
      const addressSet = new Set([...addrs, ...laddrs])
      const addresses = Array.from(addressSet).filter(a => a != "")
      addresses.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)
      return [...addresses]
    },
    orderList() {
      const orders = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.order_ids : []
      const legacy = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.legacy_order_ids : []
      return [...orders, ...legacy]
    },
    campaignList() {
      const campaigns = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.campaigns : []
      return campaigns.filter(c => c != null && c !== "")
    },
    usersAndCampaigns() {
      return [...this.userList, ...this.campaignList]
    },
    recipients() {
      const recipient_names = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.recipient_names : []
      const recipient_emails = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.recipient_emails : []
      return [...recipient_names, ...recipient_emails].filter(r => r != null && r !== "")
    },
    orderCount() {
      return this.orderList.length
    },
    orderStatuses() {
      const statuses = this.$store.getters.orderOptionsFetched ? this.$store.getters.orderFilterOptions.statuses : []
      return statuses.map(([value, name]) => ({ value, name }))
    },
    carrierStatuses() {
      const carrier_statuses = this.$store.getters.orderOptionsFetched ? this.$store.getters.orderFilterOptions.carrier_statuses : []
      return carrier_statuses.map(([value, name]) => ({ value, name }))
    },
    allStatuses() {
      return [...this.orderStatuses, ...this.customCarrierStatuses]
    },
    orderStatusMaxValue() {
      return Math.max(...this.orderStatuses.map(s => s.value))
    },
    validOrderStatusFilter() {
      return this.statusFilter && this.statusFilter <= this.orderStatusMaxValue
    },
    carrierStatusFilter() {
      if (!this.statusFilter || this.statusFilter <= this.orderStatusMaxValue) {
        return null
      }
      return this.statusFilter
    },
    // TODO: for removing
    itemNameList() {
      let itemList = []
      const prods = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.product_names : []
      const legacy = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.legacy_product_names : []
      const lease = this.$store.state.orderOptionsFetched ? this.$store.state.orderFilterOptions.lease_product_names : []
      itemList = [...prods, ...legacy, ...lease]
      const items = new Set(itemList)
      return [...items].filter(p => p != "" && p != null).sort()
    },
    isManager() {
      return this.$store.state.membership.group_data.order_history_all_users
    },
    legacyOrders() {
      return this.$store.getters.legacyOrders
    },
    dateList() {
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      let startMonth = 1;
      let startYear = 2020;
      let dates = [];
      while (startYear < year || (startMonth <= month && startYear <= year)) {
        dates.push(startMonth + "/" + startYear);
        startMonth += 1;
        if(startMonth > 12){
          startMonth = 1;
          startYear += 1;
        }
      }
      return dates.reverse();
    },
    filterParams() {
      let params = ""
      params = this.orderFilter ? params + "o=" + encodeURIComponent(this.orderFilter) : params
      params = this.statusFilter && this.validOrderStatusFilter ? params + "&s=" + encodeURIComponent(this.statusFilter) : params
      params = this.carrierStatusFilter ? params + "&cs=" + encodeURIComponent(this.carrierStatusFilter) : params
      params = this.userCampaignFilter ? params + "&uc=" + encodeURIComponent(this.userCampaignFilter) : params
      params = this.recipientFilter ? params + "&r=" + encodeURIComponent(this.recipientFilter) : params
      if(params.startsWith("&")) {
        params = params.slice(1)
      }
      return params !== "" ? params : null
    },
  },
  methods: {
     openEdit(item) {
       this.$router.push({ name: 'orders', params: { tab: 'view', itemId: item.id }}).catch(() => {});
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    parseDate(date_str){
      const date = new Date(date_str);
      const month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
    getSKU(prod_ext_id, var_sku){
      return var_sku ? var_sku : prod_ext_id && prod_ext_id != "null" ? prod_ext_id : "";
    },
    filteredLegacyOrders(){
      if(this.visibleLegacyCount === 0 && this.visibleCount < this.filteredOrderCount){
        return []
      }
      let orders = this.legacyOrders;
      if(this.userCampaignFilter != null){
        orders = orders.filter(o => o.user_name === this.userCampaignFilter || o.campaign_name === this.userCampaignFilter)
      }
      if(this.orderFilter != null){
        orders = orders.filter(o => o.order_id === this.orderFilter)
      }
      this.filteredLegacyOrderCount = orders.length;
      return orders.slice(0,this.visibleLegacyCount);
    },
    exportOrders(){
      if(this.dateList.indexOf(this.exportStart) < this.dateList.indexOf(this.exportEnd)){
        this.$toastr.e(
          "'From' has to be before or the same month as 'Until'"
        );
        return;
      }
      const vm = this;
      this.updating = true;
      const store = this.$store.getters.activeStore;
      this.$http({url: process.env.VUE_APP_API_URL + "/api/stores/" + store.id + "/orders/export/", data: {'start': this.exportStart, 'end': this.exportEnd}, method: 'POST' })
        .then(function() {
          vm.$toastr.s(
            "Order history has been exported as XLS file and will be sent to you via email shortly."
          );
          vm.updating = false;
          vm.hideExportModal();
        })
        .catch(error => {
          vm.updating = false;
          if (error.request){
            vm.$toastr.e(
              error.request.responseText
            );
          }
          vm.$toastr.e(
            error
          );
        })
    },
    openExportModal(){
      // Set defaults
      this.exportStart = this.dateList[0];
      this.exportEnd = this.dateList[0];
      this.showModal = true;
    },
    hideExportModal(){
      this.showModal = false;
    },
    getHashParams(hash) {
      const params = {}
      hash.split('&').map(hk => {
        let temp = hk.split('=')
        params[temp[0]] = temp[1]
      })
      return params
    },
    setFiltersWithHash(hash) {
      const params = this.getHashParams(hash)
      window.hashparams = params
      this.hash = hash
      this.orderFilter = params['o'] ? decodeURIComponent(params['o']) : null
      this.statusFilter = params['s'] ? decodeURIComponent(params['s']) : null
      this.userCampaignFilter = params['uc'] ? decodeURIComponent(params['uc']) : null
      this.recipientFilter = params['r'] ? decodeURIComponent(params['r']) : null
    },
    handleScroll(){
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight
      if(bottomOfWindow){
        this.$store.dispatch('fetchOrders', {append: true});
      }
    },
    getCustomCarrierStatus(carriers) {
      const status = {
        name: "N/A",
        active: false,
        warning: false,
        alert: false,
      }
      if (carriers.every(c => c.status === 6)) {
        status.name = "Delivered"
        status.active = true
      } else if (carriers.every(c => c.status === 3 || c.status === 4 || c.status === 5)) {
        status.name = "Exception"
        status.alert = true
      } else if (carriers.some(c => c.status === 2)) {
        status.name = "Processed"
        status.warning = true
      } else if (carriers.every(c => c.status === 1)) {
        status.name = "Unprocessed"
        status.warning = true
      } else if (carriers.some(c => c.status === null && c.parcels.length === 0)) {
        status.name = "No tracking yet"
        status.warning = true
      } else if (carriers.some(c => c.status === null && c.parcels.length !== 0)) {
        status.name = "Tracking available"
        status.active = true
      }
      return status
    },
  }
}
</script>

